<template>
  <div class="py-2 px-0 mx-auto d-flex justify-center">
    <v-card
      max-width="600"
      tile
      elevation="0"
      class="d-flex flex-column align-center justify-center"
    >
      <v-card-title>
        <v-icon left color="accentSuite">ph-fill ph-crown-simple</v-icon>
        <span> {{ $t("common.upsellTitle") }} </span>
      </v-card-title>

      <v-card-text class="font-weight-medium text-center pb-4 mb-6">
        {{ $t(invitationText) }}
      </v-card-text>
      <v-btn
        color="accent"
        class="text-none font-weight-medium text-body-2 white--text"
        elevation="0"
        @click="goToSelfCheckout"
      >
        {{ $t("common.checkPlans") }}
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters } from "vuex";
export default {
  name: "InviteToUpsell",
  props: {
    analyticsClick: { type: String, required: true },
    analyticsDescription: { type: String, required: true },
    invitationText: { type: String, required: true },
  },
  computed: {
    ...mapGetters(["mainDomain", "currentUser"]),
  },
  methods: {
    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: this.analyticsClick,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: this.analyticsDescription,
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },
  },
};
</script>
<style lang=""></style>
